import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import config from '../config';
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // $('body').addClass('loading-body');
    // $('.mobile-nav').removeClass('open');
    // setTimeout(() => {
    //   $('body').removeClass('loading-body');
    // }, 1000)
  }, [pathname]);

  return null;
}
