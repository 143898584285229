import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import config from '../config';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
   
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(config.adminbaseUrl+"check-auth");
        if (response.data.authenticated) {
          setAuthStatus(true);
          setUserId(response.data.id);
        } else {
          setAuthStatus(false);
        }
      } catch (error) {
        setAuthStatus(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ authStatus, userId, setAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
