import React from 'react'

function Blog_detail() {
    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>Blog Detail</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className="blog-detail-sec sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                            <div className="blog-detail-card-main">
                                <div className="blog-detail-card">
                                    <div className="blog-detail-card-img">
                                        <img src="images/blog-detail-card1.jpg" alt="img" />
                                    </div>
                                    <div className="blog-detail-card-txt-main">
                                        <div className="blog-detail-card-txt">
                                            <div className="blog-detail-card-heading-user">
                                                <div className="blog-detail-card-heading">
                                                    <h4>dolor sit amet consecteutr elit sed do quis</h4>
                                                </div>
                                                <div className="blog-list-card-user">
                                                    <ul>
                                                        <li>
                                                            <a href="#">
                                                                <i className="fa-regular fa-user" />
                                                            </a>
                                                            <p>By Admin</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                unknown printer took a galley of type and scrambled it to make a type specimen
                                                book.
                                                It has survived not only five centuries, but also the leap into electronic
                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s
                                                with
                                                the release of Letraset sheets containing Lorem Ipsum passages, and more
                                                recently
                                                with desktop publishi.Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                                ever
                                                since the 1500s, when an unknown printer took a galley of type and scrambled it
                                                to
                                                make a type specimen book. It has survived not only five centuries, but also the
                                                leap into electronic typesetting, remaining essentially unchanged. It was
                                                popularised in the 1960s with the release of Letraset sheets containing Lorem
                                                Ipsum
                                                passages, and more recently with desktop publishi.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-detail-search-recent-tags-main">
                                <div className="blog-detail-search-main">
                                    <div className="blog-detail-search">
                                        <input type="search" name="bd-search" id="bd-search" placeholder="Enter Search Keywords" />
                                    </div>
                                    <div className="blog-detail-search-icon">
                                        <button type="submit">
                                            <i className="fal fa-search" />
                                        </button>
                                    </div>
                                </div>
                                <div className="blog-detail-recent-main">
                                    <div className="blog-detail-recent">
                                        <h5>Recent Blog pOSTS</h5>
                                    </div>
                                    <div className="blog-list-card-main">
                                        <div className="blog-list-card">
                                            <div className="blog-list-card-img">
                                                <a href="blog-detail.html">
                                                    <img src="images/blog-list-card5.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div className="blog-list-card-txt-main">
                                                <div className="blog-list-card-txt">
                                                    <a href="blog-detail.html">
                                                        <h5>dolor sit amet consecteutr elit sed do quis</h5>
                                                    </a>
                                                </div>
                                                <div className="blog-list-card-user-icon-main">
                                                    <ul>
                                                        <li>
                                                            <a href="#">
                                                                <i className="fa-regular fa-user" />
                                                            </a>
                                                            <p>By Admin</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-list-card-main">
                                        <div className="blog-list-card">
                                            <div className="blog-list-card-img">
                                                <a href="blog-detail.html">
                                                    <img src="images/blog-list-card6.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div className="blog-list-card-txt-main">
                                                <div className="blog-list-card-txt">
                                                    <a href="blog-detail.html">
                                                        <h5>dolor sit amet consecteutr elit sed do quis</h5>
                                                    </a>
                                                </div>
                                                <div className="blog-list-card-user-icon-main">
                                                    <ul>
                                                        <li>
                                                            <a href="#">
                                                                <i className="fa-regular fa-user" />
                                                            </a>
                                                            <p>By Admin</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-list-card-main">
                                        <div className="blog-list-card">
                                            <div className="blog-list-card-img">
                                                <a href="blog-detail.html">
                                                    <img src="images/blog-list-card7.jpg" alt="img" />
                                                </a>
                                            </div>
                                            <div className="blog-list-card-txt-main">
                                                <div className="blog-list-card-txt">
                                                    <a href="blog-detail.html">
                                                        <h5>dolor sit amet consecteutr elit sed do quis</h5>
                                                    </a>
                                                </div>
                                                <div className="blog-list-card-user-icon-main">
                                                    <ul>
                                                        <li>
                                                            <a href="#">
                                                                <i className="fa-regular fa-user" />
                                                            </a>
                                                            <p>By Admin</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-detail-tags-main">
                                    <div className="blog-detail-tags">
                                        <h5>Tags</h5>
                                    </div>
                                    <div className="blog-detail-tags-key">
                                        <a href="#">
                                            <span>lorem ipsum</span>
                                        </a>
                                        <a href="#">
                                            <span>ipsum</span>
                                        </a>
                                        <a href="#">
                                            <span>Lorem</span>
                                        </a>
                                        <a href="#">
                                            <span>dummy</span>
                                        </a>
                                        <a href="#">
                                            <span>lorem ipsum dolor</span>
                                        </a>
                                        <a href="#">
                                            <span>lorem ipsum dolor site</span>
                                        </a>
                                        <a href="#">
                                            <span>Dolor Smit</span>
                                        </a>
                                        <a href="#">
                                            <span>lorem ipsum dummy</span>
                                        </a>
                                        <a href="#">
                                            <span>Text</span>
                                        </a>
                                        <a href="#">
                                            <span>dummy</span>
                                        </a>
                                        <a href="#">
                                            <span>dummy Text Here Placeholder</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Blog_detail
