import React from 'react'

function Blog() {
    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>Blogs</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className="blog_sec sec ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="heading text_center topbar">
                                <h2>Tips &amp; Resources</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum eleifend
                                    pellentesque. Quisque eu scelerisque
                                    arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum sodales</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog1.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog2.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog3.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog4.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog5.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_box">
                                <div className="blog_box_image">
                                    <img src="images/blog6.jpg" alt />
                                </div>
                                <div className="blog_box_text">
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-user" />
                                            <p>By Admin</p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-comment-dots" />
                                            <p>02 Comments</p>
                                        </li>
                                    </ul>
                                    <h4>Consec Adipisicing Elit</h4>
                                    <p>Quisque eu scelerisque arcu, ut facilisis nisi. Maecenas luctus, purus sed bibendum
                                        sodales</p>
                                    <a href="/blog-detail">MOre Detail</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Blog
