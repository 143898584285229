import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSettings } from '../context/SettingsContext';
import config from '../config';

const fetchContentApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-content/FooterPage');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export default function Footer() {
    const { settings, loading, error } = useSettings();
    const [content, setContent] = useState([]);
    const [email, setEmail] = useState('');
    const [newsletterMessage, setNewsletterMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    }, []);

    const handleNewsletterSubmit = async () => {
        if (!email) {
            setNewsletterMessage('Please enter an email address.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setNewsletterMessage('Please enter a valid email address.');
            return;
        }

        try {
            const response = await axios.post(
                `https://afrzon.demo-customproject.com/backend/api/set-newsletter?email=${email}`
            );

            if (response.data.status && response.data.code === 200) {
                setNewsletterMessage('Subscription successful!');
                setEmail('');
            } else {
                setNewsletterMessage(response.data.message || 'Subscription failed. Please try again.');
            }
        } catch (error) {
            console.error('Error subscribing to the newsletter:', error);
            setNewsletterMessage('An error occurred. Please try again later.');
        }
    };


    return (
        <div>
            <footer>
                <div className="footerSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="footer-contact">
                                    <div className="footer-logo">
                                        <img src={`${config.adminbaseUrl}assets/images/${content[0]?.image}`} alt="img" className="FooterLogo" />
                                    </div>
                                    {renderHTML(content[0]?.details)}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>Quick Links</h5>
                                    <ul className="f-nav">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="/products">PRODUCTS</Link></li>
                                        <li><Link to={`${config.adminbaseUrl}`}>ADVERTISEMENT</Link></li>
                                        <li><Link to={`${config.adminbaseUrl}`}>ACCOUNT</Link></li>
                                        <li><Link to="/contact">CONTACT US</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>Resource</h5>
                                    <ul className="f-nav">
                                        <li><Link to="/faq">FAQ's</Link></li>
                                        <li><Link to="/blogs">BLOGS</Link></li>
                                        <li><Link to="/price-plan">PRICE PLAN</Link></li>
                                        <li><Link to="/cookie-policy">COOKIE POLICY</Link></li>
                                        <li><Link to="/copyright-policy">COPYRIGHT POLICY</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>Contact Info</h5>
                                    <ul className="f-contact">
                                        <li>
                                            <Link to={`mailto:${settings?.email}`}>
                                                <i className="fa-solid fa-envelope" />
                                                <p>{settings?.email}</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>{content[1]?.title}</h5>
                                    {renderHTML(content[1]?.details)}
                                    <div className="f-input">
                                        <input
                                            type="email"
                                            name="newsletterEmail"
                                            placeholder="Your Email Address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <button type="button" className="btn-1 btn2" onClick={handleNewsletterSubmit}>
                                            SUBSCRIBE NOW
                                        </button>
                                    </div>
                                    {newsletterMessage && <p className="newsletter-message">{newsletterMessage}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-btm">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="copy-txt">
                                        <Link to="/terms-and-conditions">TERMS AND CONDITIONS</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="copy-txt">
                                        <p>{settings?.copyright}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 text-end">
                                    <div className="copy-txt">
                                        <Link to="/privacy-policy">PRIVACY POLICY</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
