import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, Routes } from "react-router-dom";
import Header from './components/Header';
import ScrollToTop from './components/ScrollTop';
import Home from './pages/Home';
import Footer from './components/Footer';
import './App.css';
import Signup from './pages/Signup';
import Products from './pages/Products';
import Postadd from './pages/Postadd';
import Advertisement from './pages/Advertisement';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import Faq from './pages/Faq';
import Blog from './pages/Blog';
import Blog_detail from './pages/Blog_detail';
import Price_plan from './pages/Price_plan';
import Cookie_policy from './pages/Cookie_policy';
import Copyright_policy from './pages/Copyright_policy';

const App = () => {
    const location = useLocation();

    useEffect(() => {
        nprogress.start();
        const timer = setTimeout(() => nprogress.done(), 300);
        return () => {
            clearTimeout(timer);
            nprogress.done();
        };
    }, [location]);

    return (
        <>
            <Header />
            <ScrollToTop />
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/products" element={<Products />} />
                <Route path="/post-add/:id" element={<Postadd />} />
                <Route path="/advertisement" element={<Advertisement />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/blogs" element={<Blog />} />
                <Route path="/blog-detail" element={<Blog_detail />} />
                <Route path="/price-plan" element={<Price_plan />} />
                <Route path="/cookie-policy" element={<Cookie_policy />} />
                <Route path="/copyright-policy" element={<Copyright_policy />} />
            </Routes>
            <Footer />
        </>
    );
};

const WrappedApp = () => (
    <Router>
        <App />
    </Router>
);

export default WrappedApp;
