import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"; 
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import config from '../config';
const OfferModal = ({ show, handleClose, basePrice, adId }) => {
 const { authStatus, userId } = useAuth();
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [customPrice, setCustomPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");


  const priceOptions = [
    basePrice * 1.05,
    basePrice * 1.07,
    basePrice * 1.1,
    basePrice * 1.12,
    basePrice * 1.15,
  ];

  const handleSelectPrice = (price) => {
    setSelectedPrice(price);
    setCustomPrice("");
  };
  const handleCustomPriceChange = (e) => {
    setCustomPrice(e.target.value);
    setSelectedPrice(null);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const priceToSubmit = selectedPrice || parseFloat(customPrice);
    if (priceToSubmit < basePrice * 0.7) {
      setErrorMessage("Price cannot be lower than 70% of the base price.");
      return;
    }

    try {
     console.log(userId, adId, priceToSubmit);
      const response = await axios.post(config.apiUrl + "submit-offer", {
        user_id: userId,
        ad_id: adId,
        offer_price: priceToSubmit
      });

      if (response.data.success) {
       
        setSuccessMessage("Your offer has been submitted successfully.");
        setError("");
        // handleClose();
      } else {
        setError("Failed to submit the offer. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error submitting offer:", error);
      setError("An error occurred while submitting your offer.");
      setSuccessMessage("");
    }
  };


  return (
    <Modal show={show} onHide={handleClose} aria-labelledby="offerModalLabel">
      <Modal.Header closeButton>
        <Modal.Title id="offerModalLabel">Make an Offer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="price-options-container">
          {priceOptions.map((price, index) => (
            <div
              key={index}
              className={`price-option ${selectedPrice === price ? "selected" : ""}`}
              onClick={() => handleSelectPrice(price)}
            >
              ${price.toFixed(2)}
            </div>
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          <label htmlFor="priceInput" className="form-label mt-4">
            Enter your price
          </label>
          <input
            type="number"
            className="form-control mt-0"
            id="priceInput"
            value={customPrice}
            onChange={handleCustomPriceChange}
            placeholder="Enter your price"
          />
          {errorMessage && <div className="error-message text-danger">{errorMessage}</div>}
          {error && <div className="error-message text-danger">{error}</div>}
          {successMessage && <div className="success-message text-success">{successMessage}</div>}

          <Button type="submit" className="btn btn-success w-100 mt-3">
            Send Offer
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default OfferModal;
