import React, { createContext, useState, useEffect, useContext } from 'react';
import config from '../config';
// 1. Create the context
const SettingsContext = createContext();

// 2. Create the provider component
export const SettingsProvider = ({ children }) => {

  const [settings, setSettings] = useState(null); // Initially, set to null or {} depending on your use case
  const [loading, setLoading] = useState(true);   // To track loading state
  const [error, setError] = useState(null);       // To handle errors


    // Fetch data from API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.apiUrl+'get-settings'); // Replace with your API URL
        const data = await response.json();
        setSettings(data.data); // Set the settings data to state
      } catch (err) {
        setError('Failed to fetch settings');
      } finally {
        setLoading(false); // Set loading to false after the data is fetched
      }
    };

    fetchData();
  }, []); // Empty dependency array means this will run only once on mount


  return (
    <SettingsContext.Provider value={{ settings, loading, error }}>
      {children}
    </SettingsContext.Provider>
  );
};


export const useSettings = () => {
  return useContext(SettingsContext);
};